import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabase/client';
import Header from './Header';
import { formatDistanceToNow } from 'date-fns';

function Home() {
  const [latestSongs, setLatestSongs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchLatestSongs() {
      try {
        const { data, error } = await supabase
          .from('songs')
          .select('*')
          .order('created_at', { ascending: false })
          .limit(20);

        if (error) throw error;
        setLatestSongs(data || []); // Asegurar que siempre tenemos un array
      } catch (error) {
        console.error('Error fetching latest songs:', error);
        setLatestSongs([]); // En caso de error, establecer un array vacío
      } finally {
        setLoading(false);
      }
    }

    fetchLatestSongs();
  }, []);

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <main className="container mx-auto px-4 py-8">
        {latestSongs.length > 0 ? (
          <ul className="max-w-2xl mx-auto">
            {latestSongs.map((song) => (
              <li key={song.id}>
                <Link to={`/song/${song.song_id}`} className="flex items-center justify-between p-2 rounded-lg hover:bg-gray-50">
                  <div className="flex items-center">
                    <img 
                      src={song.image_url} 
                      alt={`${song.title} by ${song.artist}`}
                      className="w-10 h-10 rounded-full object-cover mr-4"
                    />
                    <div>
                      <h2 className="font-semibold text-lg">{song.title}</h2>
                      <p className="text-gray-600">{song.artist}</p>
                    </div>
                  </div>
                  <div className="text-sm text-gray-500">
                    {formatDistanceToNow(new Date(song.created_at), { addSuffix: true })}
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-center text-gray-500">No songs found.</p>
        )}
      </main>
    </div>
  );
}

export default Home;