import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

export default function AuthCallback() {
  const navigate = useNavigate();
  const { loading } = useAuth();

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        if (loading) return;

        const savedPath = localStorage.getItem('authRedirect');
        console.log('Auth callback - saved path:', savedPath);
        
        if (savedPath && savedPath !== '/auth/callback') {
          localStorage.removeItem('authRedirect');
          console.log('Redirecting to:', savedPath);
          navigate(savedPath, { replace: true });
        } else {
          console.log('No saved path or invalid path, going to home');
          navigate('/', { replace: true });
        }
      } catch (error) {
        console.error('Error en autenticación:', error);
        navigate('/', { replace: true });
      }
    };

    handleAuthCallback();
  }, [navigate, loading]);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
    </div>
  );
}