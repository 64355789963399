// src/components/SpotifyExtractor.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabase/client';

function SpotifyExtractor() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [unsupportedType, setUnsupportedType] = useState(null);

  useEffect(() => {
    const extractAndSave = async () => {
      try {
        // Extraer la URL de Spotify del path y decodificarla
        const spotifyUrl = decodeURIComponent(location.pathname.slice(1));
        
        // Validar que es una URL de Spotify
        if (!spotifyUrl.includes('spotify.com')) {
          throw new Error('Invalid Spotify URL');
        }

        // Extraer el tipo de contenido y el ID
        const urlParts = spotifyUrl.split('/');
        const contentType = urlParts[urlParts.length - 2]; // track, album, artist, etc.
        const contentId = urlParts[urlParts.length - 1];

        // Limpiar contentId de parámetros de URL si los tiene
        const cleanContentId = contentId.split('?')[0];

        // Si no es un track, guardar en unsupported_urls y mostrar mensaje
        if (contentType !== 'track') {
          console.log('Detectada URL no soportada:', {
            url: spotifyUrl,
            type: contentType
          });

          try {
            // Guardar en unsupported_urls
            const { data, error: insertError } = await supabase
              .from('unsupported_urls')
              .insert({
                url: spotifyUrl,
                type: contentType,
              })
              .select()
              .single();

            if (insertError) {
              console.error('Error al guardar URL no soportada:', insertError);
              throw insertError;
            }

            console.log('URL no soportada guardada exitosamente:', data);
          } catch (unsupportedError) {
            console.error('Error completo al guardar URL no soportada:', unsupportedError);
          }

          setUnsupportedType(contentType);
          setLoading(false);
          return;
        }

        // Verificar si la canción ya existe
        const { data: existingSong, error: fetchError } = await supabase
          .from('songs')
          .select('id, song_id')
          .eq('song_id', cleanContentId)
          .single();

        if (fetchError && fetchError.code !== 'PGRST116') {
          throw fetchError;
        }

        if (existingSong) {
          navigate(`/song/${existingSong.song_id}`);
          return;
        }

        // Si la canción no existe, proceder con la extracción y guardado
        const apiUrl = `https://spotify81.p.rapidapi.com/tracks?ids=${cleanContentId}`;
        const options = {
          method: 'GET',
          headers: {
            'x-rapidapi-key': '7a6f2912bemsh6c59d3d76a614bcp1c3764jsn6ddb328c0829',
            'x-rapidapi-host': 'spotify81.p.rapidapi.com'
          }
        };

        const response = await fetch(apiUrl, options);
        if (!response.ok) {
          throw new Error('Failed to fetch track data');
        }

        const result = await response.json();

        if (!result.tracks || result.tracks.length === 0) {
          throw new Error('No track data found');
        }

        const track = result.tracks[0];

        // Obtener el usuario actual
        const { data: { user } } = await supabase.auth.getUser();

        // Crear o actualizar el artista
        const { error: artistError } = await supabase
          .from('artists')
          .upsert({
            artist_id: track.artists[0].id,
            name: track.artists[0].name
          }, { onConflict: 'artist_id' });

        if (artistError) throw artistError;
        
        const songData = {
          title: track.name,
          song_id: track.id,
          artist: track.artists[0].name,
          artist_id: track.artists[0].id,
          image_url: track.album.images[0].url,
          album: track.album.name,
          source: `https://open.spotify.com/track/${cleanContentId}`,
          preview_url: track.preview_url,
          created_by: user?.id || null
        };

        const { data, error } = await supabase
          .from('songs')
          .insert([songData])
          .select()
          .single();

        if (error) throw error;

        navigate(`/song/${data.song_id}`);
      } catch (err) {
        console.error('Error in SpotifyExtractor:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    extractAndSave();
  }, [location.pathname, navigate]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p>Loading...</p>
      </div>
    );
  }

  if (unsupportedType) {
    return (
      <div className="flex justify-center items-center h-screen flex-col gap-4">
        <p>👋</p>
        <p className="text-xl text-gray-600 text-center px-4">Sorry, we only support <b>track</b> links.</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-xl text-red-600">
          {error === 'Invalid Spotify URL' 
            ? 'La URL proporcionada no es válida' 
            : 'Ha ocurrido un error al procesar la URL'}
        </p>
      </div>
    );
  }

  return null;
}

export default SpotifyExtractor;