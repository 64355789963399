import React, { useState, useEffect } from 'react';
import { supabase } from '../supabase/client';
import { formatDistanceToNow } from 'date-fns';
import { useAuth } from '../context/AuthContext';

function Comments({ pageId, pageType }) {
  const { user } = useAuth();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchComments();
  }, [pageId, pageType]);

  const fetchComments = async () => {
    try {
      setLoading(true);
      const { data: commentsData, error: commentsError } = await supabase
        .from('comments')
        .select('*')
        .eq('page_id', pageId)
        .eq('page_type', pageType)
        .order('created_at', { ascending: false });

      if (commentsError) throw commentsError;

      const userIds = [...new Set(commentsData.map(comment => comment.user_id))];
      
      const { data: profilesData, error: profilesError } = await supabase
        .from('user_profiles')
        .select('id, avatar_url, handle')
        .in('id', userIds);

      if (profilesError) throw profilesError;

      const profilesMap = Object.fromEntries(profilesData.map(profile => [profile.id, profile]));

      const commentsWithProfiles = commentsData.map(comment => ({
        ...comment,
        user_profile: profilesMap[comment.user_id] || null
      }));

      setComments(commentsWithProfiles);
    } catch (error) {
      setError('Error fetching comments');
      console.error('Error fetching comments:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setError('You must be logged in to comment');
      return;
    }
    if (!newComment.trim()) return;

    try {
      const { data: commentData, error: commentError } = await supabase
        .from('comments')
        .insert([
          { 
            content: newComment, 
            page_id: pageId, 
            page_type: pageType, 
            user_id: user.id 
          }
        ])
        .select()
        .single();

      if (commentError) throw commentError;

      const newCommentWithProfile = {
        ...commentData,
        user_profile: {
          id: user.id,
          avatar_url: user.avatar_url,
          handle: user.handle
        }
      };

      setComments([newCommentWithProfile, ...comments]);
      setNewComment('');
    } catch (error) {
      setError('Error posting comment');
      console.error('Error posting comment:', error);
    }
  };

  const getUserInitials = (user) => {
    if (user.display_name) {
      return user.display_name.split(' ').map(n => n[0]).join('').toUpperCase();
    } else if (user.email) {
      return user.email.substring(0, 2).toUpperCase();
    }
    return 'U';
  };

  const UserAvatar = ({ user, size = 'h-10 w-10' }) => {
    if (user.avatar_url) {
      return (
        <img
          src={user.avatar_url}
          alt={`${user.handle || user.email}'s avatar`}
          className={`${size} rounded-full object-cover`}
        />
      );
    } else {
      return (
        <div className={`${size} rounded-full bg-blue-500 flex items-center justify-center text-white font-bold`}>
          {getUserInitials(user)}
        </div>
      );
    }
  };

  if (loading) return <div>Loading comments...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-bold mb-4">Comments</h2>
      {user ? (
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
              <UserAvatar user={user} />
            </div>
            <div className="min-w-0 flex-1">
              <textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500 mb-2"
                placeholder="Post a comment..."
                rows="3"
              />
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Post
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="mb-6 p-4 bg-gray-100 rounded-md">
          <p className="text-gray-700">
            Sign in to post comments. 
          </p>
        </div>
      )}
      <div className="space-y-6">
        {comments.map((comment) => (
          <div key={comment.id} className="flex space-x-3">
            <div className="flex-shrink-0">
              <UserAvatar user={comment.user_profile || {email: 'Unknown'}} />
            </div>
            <div>
              <div className="text-sm">
                <span className="font-bold text-gray-900">
                  @{comment.user_profile?.handle || 'Unknown user'}
                </span>
                <span className="text-gray-500 ml-2">
                  {formatDistanceToNow(new Date(comment.created_at), { addSuffix: true })}
                </span>
              </div>
              <div className="mt-1 text-sm text-gray-700">
                <p>{comment.content}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Comments;