import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabase/client';
import { useAuth } from '../context/AuthContext';
import Comments from './Comments';

function SongPage() {
  const { id } = useParams();
  const [song, setSong] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchSong = async () => {
      const { data, error } = await supabase
        .from('songs')
        .select('*')
        .eq('song_id', id)  // Cambiamos 'id' por 'song_id'
        .single();
  
      if (error) {
        console.error('Error fetching song:', error);
      } else {
        setSong(data);
      }
    };
  
    fetchSong();
  }, [id]);

  if (!song) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-white text-gray-900">
      <main className="container mx-auto px-4 py-8">
        <div className="flex flex-col w-full max-w-3xl mx-auto">
          <div className="flex flex-col md:flex-row items-center md:items-start mb-8">
            <div className="w-48 md:w-56 mb-4 md:mb-0 md:mr-8 flex-shrink-0">
              <img 
                src={song.image_url} 
                alt={`${song.title} by ${song.artist}`}
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
            <div className="flex flex-col justify-between h-56 md:h-56 w-full">
              <div>
                <p className="text-sm uppercase tracking-wider text-gray-600 mb-1">Song</p>
                <h1 className="text-3xl font-bold mb-2">{song.title}</h1>
                <p className="text-xl text-gray-700 mb-4">{song.artist}</p>
              </div>
              <div className="mt-auto">
                {song.preview_url && (
                  <div className="mb-2">
                    <audio controls className="w-full max-w-xs">
                      <source src={song.preview_url} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                )}
                <a 
                  href={song.source} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 transition duration-300"
                >
                  Listen on Spotify
                </a>
              </div>
            </div>
          </div>
          <Comments pageId={song.id} pageType="song" user={user} />
        </div>
      </main>
    </div>
  );
}

export default SongPage;